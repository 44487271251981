import {
  Typography,
  Paper,
  Button,
  CircularProgress,
  TextField
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { styled } from './emotion';

const FormWrapper = styled(Paper)`
  padding: ${(p) => p.theme.spacing(6)}px ${(p) => p.theme.spacing(4)}px;
  margin: ${(p) => p.theme.spacing(12)}px auto ${(p) => p.theme.spacing(2)}px;
  max-width: 500px;
  min-width: 320px;

  @media (max-width: 500px) {
    margin: ${(p) => p.theme.spacing(2)}px auto ${(p) => p.theme.spacing(2)}px;
  }
`;

const Form = styled('form')``;

const FormActionsFooter = styled('div')`
  text-align: center;
  margin-top: ${(p) => p.theme.spacing(4)}px;
`;

const FormActionLink = styled('a')`
  color: ${(p) => p.theme.palette.grey['300']} !important;
  display: block;
  font-size: 0.85rem;
  margin: 0 auto;
`;

const FormRow = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

type State = {
  email: string;
  password: string;
  loading: boolean;
  error?: ErrorCode;
};

const INITIAL_STATE = {
  email: '',
  password: '',
  loading: false,
  error: undefined
};

const Loader = styled(CircularProgress)`
  width: 20px !important;
  height: 20px !important;
  margin-right: ${(p) => p.theme.spacing(1)}px;

  svg {
    color: inherit;
  }
`;

const FullWidthButton = styled(Button)`
  width: 100%;
`;

const CF_DOMAIN = 'https://us-central1-bloggingfordevs.cloudfunctions.net';

const postToCf = async <T extends object = any>(
  fnName: string,
  data: object,
  headers: { [key: string]: string } = {}
): Promise<T> => {
  const url = `${CF_DOMAIN}/${fnName}`;

  const parseResponse = (res: string) => {
    try {
      return res ? JSON.parse(res) : undefined;
    } catch (e) {
      return res;
    }
  };

  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open('POST', url);
    for (const name in headers) {
      req.setRequestHeader(name, headers[name]);
    }
    req.setRequestHeader('Content-Type', 'application/json');
    req.send(JSON.stringify(data));
    req.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status >= 200 && this.status < 400) {
          resolve(parseResponse(req.responseText));
          return;
        }
        reject(parseResponse(req.responseText));
      }
    };
  });
};

const StyledA = styled('a')`
  border-bottom: 1px solid #a4acb6;
`;

const InfoMessage = styled('div')`
  border-radius: 8px;
  background-color: #bae7ff;
  color: #096dd9;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 12px 24px;

  a {
    border-bottom: 1px solid #096dd9;
  }
`;

const WarningMessage = styled('div')`
  border-radius: 8px;
  background-color: #ffe58f;
  color: #613400;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 12px 24px;

  a {
    border-bottom: 1px solid #613400;
  }
`;

type ErrorCode =
  | 'INVALID_EMAIL'
  | 'INVALID_PASSWORD'
  | 'USER_DISABLED'
  | 'EMAIL_NOT_FOUND';

const ERROR_MESSAGES: { [key in ErrorCode]: React.ReactNode } = {
  INVALID_EMAIL:
    'Your email address is not properly formatted. Please fix it and try again!',
  INVALID_PASSWORD:
    'Your password is incorrect. Please check it again, or use the link below to reset your password.',
  USER_DISABLED: (
    <>
      This account has been disabled. If you want to renew it, please get in
      touch at{' '}
      <a href="mailto:hi@bloggingfordevs.com">hi@bloggingfordevs.com</a>
    </>
  ),
  EMAIL_NOT_FOUND:
    "A member with this email isn't in our system. Can you double-check which email address received your welcome email?"
};

const ERROR_MESSAGE_KEYS: ErrorCode[] = [
  'INVALID_PASSWORD',
  'INVALID_EMAIL',
  'EMAIL_NOT_FOUND',
  'USER_DISABLED'
];

const LoginForm = () => {
  const [state, setState] = useState<State>(INITIAL_STATE);
  const { href } = window.location;
  const queryParams = useMemo(() => {
    const { searchParams } = new URL(href);
    return {
      redirect_uri: searchParams.get('redirect_uri') || '',
      response_type: searchParams.get('response_type') || '',
      state: searchParams.get('state') || '',
      scope: searchParams.get('scope') || ''
    };
  }, [href]);

  return (
    <Form
      onSubmit={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setState((s) => ({ ...s, error: undefined, loading: true }));

        postToCf<{ redirect: string }>('oauth-signIn', {
          email: state.email,
          password: state.password,
          ...queryParams
        }).then(
          ({ redirect }) => {
            console.log(redirect);
            window.location.href = redirect;
          },
          (err) => {
            console.error(err);
            setState((s) => ({ ...s, error: err.error, loading: false }));
          }
        );
      }}
    >
      <InfoMessage>
        <strong>Not a member yet?</strong>{' '}
        <a
          href="https://bloggingfordevs.com/pro/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here
        </a>{' '}
        to join the community :)
      </InfoMessage>
      <Typography
        variant="h6"
        component="h1"
        style={{
          fontWeight: 700,
          textAlign: 'center',
          maxWidth: '330px',
          margin: '24px auto 12px'
        }}
      >
        Welcome to the
        <br />
        Blogging for Devs Pro Community!
      </Typography>
      <Typography
        variant="body1"
        component="p"
        color="textSecondary"
        style={{
          textAlign: 'center',
          margin: '0 auto'
        }}
      >
        Our community forum is powered by{' '}
        <StyledA href="https://circle.so" rel="nofollow" target="_blank">
          Circle
        </StyledA>
        . By logging in, you're granting Circle access to your account.
      </Typography>
      <br />
      <br />
      <FormRow>
        <TextField
          variant="filled"
          InputProps={{
            style: { backgroundColor: 'white', borderRadius: '8px' },
            disableUnderline: true
          }}
          label="Email"
          fullWidth
          value={state.email}
          onChange={(ev) => {
            const email = ev.target.value;
            setState((s) => ({ ...s, email }));
          }}
          disabled={state.loading}
          required
        />
      </FormRow>
      <FormRow>
        <TextField
          variant="filled"
          InputProps={{
            style: { backgroundColor: 'white', borderRadius: '8px' },
            disableUnderline: true
          }}
          type="password"
          label="Password"
          fullWidth
          value={state.password}
          onChange={(ev) => {
            const password = ev.target.value;
            return setState((s) => ({ ...s, password }));
          }}
          disabled={state.loading}
          required
        />
      </FormRow>
      <FullWidthButton
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        disabled={state.loading || !state.email || !state.password}
      >
        {state.loading && (
          <>
            <Loader style={{ color: 'white' }} /> Logging in, it takes a
            second...
          </>
        )}
        {!state.loading && 'Log in'}
      </FullWidthButton>
      {state.error && (
        <WarningMessage style={{ marginTop: '12px' }}>
          {ERROR_MESSAGES[state.error]}
          {!ERROR_MESSAGE_KEYS.includes(state.error) && (
            <>
              An unhandled error occurred: {state.error}. Please get in touch at{' '}
              <a href="mailto:hi@bloggingfordevs.com">hi@bloggingfordevs.com</a>{' '}
              for help!
            </>
          )}
        </WarningMessage>
      )}
      <FormActionsFooter>
        <FormActionLink
          href="https://bloggingfordevs.com/reset-password/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Forget your password? Reset it here
        </FormActionLink>
      </FormActionsFooter>
      <FormActionsFooter>
        <FormActionLink
          href="https://bloggingfordevs.com/migrate/"
          target="_blank"
        >
          Need to migrate from your old Circle account? Click here
        </FormActionLink>
      </FormActionsFooter>
    </Form>
  );
};

export const Login = () => {
  return (
    <FormWrapper>
      <LoginForm />
    </FormWrapper>
  );
};
