import { PaletteColor } from '@material-ui/core/styles/createPalette';

export type ExtendedPaletteColor = PaletteColor & { lighter: string };

export interface IEMotionTheme {
  custom: {
    colors: {
      success: PaletteColor;
      pending: PaletteColor;
      error: PaletteColor;
      secondary: PaletteColor;
      primary: PaletteColor;
      background: PaletteColor;

      red: ExtendedPaletteColor;
      orange: ExtendedPaletteColor;
      green: ExtendedPaletteColor;
      blue: ExtendedPaletteColor;
      gray: ExtendedPaletteColor;

      subText: PaletteColor;
    };
    fontSize: {
      s: number;
      m: number;
      l: number;
      xl: number;
    };
    border: {
      radius: (multiplier?: number) => number;
      color: string;
      standard: string;
    };
  };
}

export const EMOTION_THEME: IEMotionTheme = {
  custom: {
    border: {
      radius: (multiplier = 1) => 4 * multiplier,
      color: 'rgba(0, 0, 0, 0.1)',
      standard: '1px solid rgba(0, 0, 0, 0.1)'
    },
    colors: {
      primary: {
        light: '#6DABE9',
        main: '#2675C5',
        dark: '#135996',
        contrastText: 'white'
      },
      secondary: {
        light: '#77D4A9',
        main: '#45BA83',
        dark: '#1A6133',
        contrastText: 'white'
      },
      success: {
        light: '#77D4A9',
        main: '#45BA83',
        dark: '#1A6133',
        contrastText: 'white'
      },
      pending: {
        light: '#F5E18C',
        dark: '#F6CC1B',
        main: '#F6CC1B',
        contrastText: 'white'
      },
      error: {
        light: '#F77B6E',
        main: '#E25E50',
        dark: '#BF3A2B',
        contrastText: 'white'
      },
      background: {
        light: '#ffffff',
        main: '#ffffff',
        dark: '#ffffff',
        contrastText: 'black'
      },

      red: {
        lighter: '#FEF6F6',
        light: '#F77B6E',
        main: '#E25E50',
        dark: '#CB3120',
        contrastText: 'white'
      },
      orange: {
        lighter: '#FEFAEC',
        light: '#FEE580',
        main: '#F6C955',
        dark: '#D0A025',
        contrastText: 'white'
      },
      green: {
        lighter: '#F1F9F5',
        light: '#77D4A9',
        main: '#45BA83',
        dark: '#1A6133',
        contrastText: 'white'
      },
      blue: {
        lighter: '#F0F5FA',
        light: '#6DABE9',
        main: '#1D70C4',
        dark: '#135996',
        contrastText: 'white'
      },
      gray: {
        lighter: '#F8FAFB',
        light: '#DDE0E3',
        main: '#BBC1C7',
        dark: '#494F55',
        contrastText: 'white'
      },
      subText: {
        light: '#4f4f4f',
        main: '#4f4f4f',
        dark: '#4f4f4f',
        contrastText: '#4f4f4f'
      }
    },
    fontSize: {
      s: 14,
      m: 16,
      l: 22,
      xl: 28
    }
  }
};
