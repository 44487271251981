import { css, Global } from '@emotion/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { Login } from './Login';
import { COMBINED_THEME, MUI_THEME } from './themes';

const styles = css`
  * {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    padding: 2rem;
    background-color: #191c20;
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
    color: #e3e9f0;
    font-size: 1rem;
  }

  a {
    outline: 0;
    text-decoration: none;
    transition: 0.7s;
    color: inherit;
  }
`;

const AppThemeProvider: React.FC = ({ children }) => (
  <MuiThemeProvider theme={MUI_THEME}>
    {(() => {
      return (
        // @ts-ignore
        <ThemeProvider theme={COMBINED_THEME}>{children}</ThemeProvider>
      );
    })()}
  </MuiThemeProvider>
);

function App() {
  return (
    <AppThemeProvider>
      <Login />
      <Global styles={styles} />
    </AppThemeProvider>
  );
}

export default App;
