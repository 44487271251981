export type IColorScheme = {
  red: {
    red1: string;
    red2: string;
    red3: string;
    red4: string;
    red5: string;
    red6: string;
    red7: string;
    red8: string;
    red9: string;
    red10: string;
  };
  volcano: {
    volcano1: string;
    volcano2: string;
    volcano3: string;
    volcano4: string;
    volcano5: string;
    volcano6: string;
    volcano7: string;
    volcano8: string;
    volcano9: string;
    volcano10: string;
  };
  orange: {
    orange1: string;
    orange2: string;
    orange3: string;
    orange4: string;
    orange5: string;
    orange6: string;
    orange7: string;
    orange8: string;
    orange9: string;
    orange10: string;
  };
  gold: {
    gold1: string;
    gold2: string;
    gold3: string;
    gold4: string;
    gold5: string;
    gold6: string;
    gold7: string;
    gold8: string;
    gold9: string;
    gold10: string;
  };
  yellow: {
    yellow1: string;
    yellow2: string;
    yellow3: string;
    yellow4: string;
    yellow5: string;
    yellow6: string;
    yellow7: string;
    yellow8: string;
    yellow9: string;
    yellow10: string;
  };
  lime: {
    lime1: string;
    lime2: string;
    lime3: string;
    lime4: string;
    lime5: string;
    lime6: string;
    lime7: string;
    lime8: string;
    lime9: string;
    lime10: string;
  };
  green: {
    green1: string;
    green2: string;
    green3: string;
    green4: string;
    green5: string;
    green6: string;
    green7: string;
    green8: string;
    green9: string;
    green10: string;
  };
  cyan: {
    cyan1: string;
    cyan2: string;
    cyan3: string;
    cyan4: string;
    cyan5: string;
    cyan6: string;
    cyan7: string;
    cyan8: string;
    cyan9: string;
    cyan10: string;
  };
  blue: {
    blue1: string;
    blue2: string;
    blue3: string;
    blue4: string;
    blue5: string;
    blue6: string;
    blue7: string;
    blue8: string;
    blue9: string;
    blue10: string;
  };
  geekblue: {
    geekblue1: string;
    geekblue2: string;
    geekblue3: string;
    geekblue4: string;
    geekblue5: string;
    geekblue6: string;
    geekblue7: string;
    geekblue8: string;
    geekblue9: string;
    geekblue10: string;
  };
  purple: {
    purple1: string;
    purple2: string;
    purple3: string;
    purple4: string;
    purple5: string;
    purple6: string;
    purple7: string;
    purple8: string;
    purple9: string;
    purple10: string;
  };
  magenta: {
    magenta1: string;
    magenta2: string;
    magenta3: string;
    magenta4: string;
    magenta5: string;
    magenta6: string;
    magenta7: string;
    magenta8: string;
    magenta9: string;
    magenta10: string;
  };
};

export const COLORS: IColorScheme = {
  red: {
    red1: '#fff1f0',
    red2: '#ffccc7',
    red3: '#ffa39e',
    red4: '#ff7875',
    red5: '#ff4d4f',
    red6: '#f5222d',
    red7: '#cf1322',
    red8: '#a8071a',
    red9: '#820014',
    red10: '#5c0011'
  },
  volcano: {
    volcano1: '#fff2e8',
    volcano2: '#ffd8bf',
    volcano3: '#ffbb96',
    volcano4: '#ff9c6e',
    volcano5: '#ff7a45',
    volcano6: '#fa541c',
    volcano7: '#d4380d',
    volcano8: '#ad2102',
    volcano9: '#871400',
    volcano10: '#610b00'
  },
  orange: {
    orange1: '#fff7e6',
    orange2: '#ffe7ba',
    orange3: '#ffd591',
    orange4: '#ffc069',
    orange5: '#ffa940',
    orange6: '#fa8c16',
    orange7: '#d46b08',
    orange8: '#ad4e00',
    orange9: '#873800',
    orange10: '#612500'
  },
  gold: {
    gold1: '#fffbe6',
    gold2: '#fff1b8',
    gold3: '#ffe58f',
    gold4: '#ffd666',
    gold5: '#ffc53d',
    gold6: '#faad14',
    gold7: '#d48806',
    gold8: '#ad6800',
    gold9: '#874d00',
    gold10: '#613400'
  },
  yellow: {
    yellow1: '#feffe6',
    yellow2: '#ffffb8',
    yellow3: '#fffb8f',
    yellow4: '#fff566',
    yellow5: '#ffec3d',
    yellow6: '#fadb14',
    yellow7: '#d4b106',
    yellow8: '#ad8b00',
    yellow9: '#876800',
    yellow10: '#614700'
  },
  lime: {
    lime1: '#fcffe6',
    lime2: '#f4ffb8',
    lime3: '#eaff8f',
    lime4: '#d3f261',
    lime5: '#bae637',
    lime6: '#a0d911',
    lime7: '#7cb305',
    lime8: '#5b8c00',
    lime9: '#3f6600',
    lime10: '#254000'
  },
  green: {
    green1: '#f6ffed',
    green2: '#d9f7be',
    green3: '#b7eb8f',
    green4: '#95de64',
    green5: '#73d13d',
    green6: '#52c41a',
    green7: '#389e0d',
    green8: '#237804',
    green9: '#135200',
    green10: '#092b00'
  },
  cyan: {
    cyan1: '#e6fffb',
    cyan2: '#b5f5ec',
    cyan3: '#87e8de',
    cyan4: '#5cdbd3',
    cyan5: '#36cfc9',
    cyan6: '#13c2c2',
    cyan7: '#08979c',
    cyan8: '#006d75',
    cyan9: '#00474f',
    cyan10: '#002329'
  },
  blue: {
    blue1: '#e6f7ff',
    blue2: '#bae7ff',
    blue3: '#91d5ff',
    blue4: '#69c0ff',
    blue5: '#40a9ff',
    blue6: '#1890ff',
    blue7: '#096dd9',
    blue8: '#0050b3',
    blue9: '#003a8c',
    blue10: '#002766'
  },
  geekblue: {
    geekblue1: '#f0f5ff',
    geekblue2: '#d6e4ff',
    geekblue3: '#adc6ff',
    geekblue4: '#85a5ff',
    geekblue5: '#597ef7',
    geekblue6: '#2f54eb',
    geekblue7: '#1d39c4',
    geekblue8: '#10239e',
    geekblue9: '#061178',
    geekblue10: '#030852'
  },
  purple: {
    purple1: '#f9f0ff',
    purple2: '#efdbff',
    purple3: '#d3adf7',
    purple4: '#b37feb',
    purple5: '#9254de',
    purple6: '#722ed1',
    purple7: '#531dab',
    purple8: '#391085',
    purple9: '#22075e',
    purple10: '#120338'
  },
  magenta: {
    magenta1: '#fff0f6',
    magenta2: '#ffd6e7',
    magenta3: '#ffadd2',
    magenta4: '#ff85c0',
    magenta5: '#f759ab',
    magenta6: '#eb2f96',
    magenta7: '#c41d7f',
    magenta8: '#9e1068',
    magenta9: '#780650',
    magenta10: '#520339'
  }
};

export type Color = keyof IColorScheme;
export type ColorDefinition = {
  color: string;
  bgColor: string;
};

type Scheme = { [K in Color]: ColorDefinition };
export const DEFAULT_COLOR_SCHEME: Scheme = {
  red: {
    color: COLORS.red.red10,
    bgColor: COLORS.red.red6
  },
  volcano: {
    color: COLORS.volcano.volcano10,
    bgColor: COLORS.volcano.volcano6
  },
  orange: {
    color: COLORS.orange.orange10,
    bgColor: COLORS.orange.orange6
  },
  gold: {
    color: COLORS.gold.gold10,
    bgColor: COLORS.gold.gold6
  },
  yellow: {
    color: COLORS.yellow.yellow10,
    bgColor: COLORS.yellow.yellow6
  },
  lime: {
    color: COLORS.lime.lime10,
    bgColor: COLORS.lime.lime6
  },
  green: {
    color: COLORS.green.green10,
    bgColor: COLORS.green.green6
  },
  cyan: {
    color: COLORS.cyan.cyan10,
    bgColor: COLORS.cyan.cyan6
  },
  blue: {
    color: COLORS.blue.blue10,
    bgColor: COLORS.blue.blue6
  },
  geekblue: {
    color: COLORS.geekblue.geekblue10,
    bgColor: COLORS.geekblue.geekblue6
  },
  purple: {
    color: COLORS.purple.purple10,
    bgColor: COLORS.purple.purple6
  },
  magenta: {
    color: COLORS.magenta.magenta10,
    bgColor: COLORS.magenta.magenta6
  }
};

// TODO - use correct gray scale
const UNKNOWN: ColorDefinition = {
  color: 'lightgray',
  bgColor: 'gray'
};

export const DEFAULT_COLORS: Color[] = [
  'red',
  'volcano',
  'orange',
  'gold',
  'yellow',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple',
  'magenta'
];

export const getColorDef = (color?: Color | null): ColorDefinition => {
  return color ? DEFAULT_COLOR_SCHEME[color] || UNKNOWN : UNKNOWN;
};
