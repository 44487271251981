import { createMuiTheme } from '@material-ui/core';
import { COLORS } from './colors';

let theme = createMuiTheme({
  typography: {
    fontFamily: 'Lato, sans-serif'
  },
  palette: {
    primary: {
      main: COLORS.blue.blue6,
      light: COLORS.blue.blue4,
      dark: COLORS.blue.blue7,
      contrastText: COLORS.blue.blue1
    },
    text: {
      secondary: '#A4ACB6'
    },
    grey: {
      100: '#F4F8FC',
      200: '#E3E9F0',
      300: '#A4ACB6',
      400: '#767E87',
      600: '#1E2226',
      700: '#22262B',
      800: '#0D0F11',
      900: '#000000'
    }
  },
  shape: {
    borderRadius: 8
  }
});

theme = {
  ...theme,
  overrides: {
    MuiCard: {
      root: {
        padding: theme.spacing(2)
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: '#1e2129'
      }
    },
    MuiButton: {
      containedPrimary: {
        background: 'linear-gradient(45deg,#1890ff 30%,#40a9ff 90%)',
        boxShadow: '0 1px 5px 0px rgba(0,80,179,0.3)',
        fontWeight: 700,
        '&:disabled': {
          background: 'linear-gradient(45deg,#1890ff 30%,#40a9ff 90%)',
          fontWeight: 'normal',
          opacity: 0.5,
          color: '#FFF !important'
        },
        '&:hover': {
          background: 'linear-gradient(45deg,#0083fc 30%,#3c9ffb 90%)'
        }
      },
      label: {
        textTransform: 'initial'
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing()
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3
      }
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        margin: '0 16px',
        minWidth: 0,
        [theme.breakpoints.up('md')]: {
          minWidth: 0
        }
      },
      textColorPrimary: {
        color: '#FFF'
      }
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing()
      }
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '16px'
      }
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854'
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#22262b',
        color: '#e3e9f0'
      },
      elevation1: {
        backgroundColor: '#22262b',
        color: '#e3e9f0',
        boxShadow: '0 1px 5px 0px rgba(0, 0, 0, 0.5)'
      },
      elevation3: {
        backgroundColor: '#22262b',
        color: '#e3e9f0',
        boxShadow: '0 1px 5px 0px rgba(0, 0, 0, 0.5)'
      },
      elevation8: {
        backgroundColor: '#22262b',
        color: '#e3e9f0',
        boxShadow: '0 5px 20px 0 rgb(0 0 0 / 55%)'
      }
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20
        }
      }
    },
    MuiAvatar: {
      root: {
        width: 48,
        height: 48,
        border: '2px solid #22262B !important'
      },
      colorDefault: {
        color: '#e3e9f0',
        backgroundColor: '#1E2226'
      }
    },
    MuiTable: {
      stickyHeader: {
        backgroundColor: theme.palette.background.paper
      }
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: theme.palette.background.paper
      }
    },
    // @ts-ignore
    MuiToggleButtonGroup: {
      root: {
        // border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius
      },
      selected: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    },
    // @ts-ignore
    MuiToggleButton: {
      root: {
        background: 'none',
        color: theme.palette.primary.main,
        border: 'none',
        '&:hover': {
          backgroundColor: `${theme.palette.primary.dark} !important`,
          color: `${theme.palette.primary.contrastText} !important`
        }
      },
      selected: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.primary.contrastText} !important`
      }
    }
  },
  props: {
    MuiTab: {
      disableRipple: true
    }
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48
    }
  }
};

export default theme;
